import * as dompack from 'dompack';
import './widgets/widgets';
import '@mod-mso/pages/events/events';
import '@mod-mso/webdesigns/mso/components/contactheader/contactheader';
import '@mod-mso/webdesigns/mso/components/socials/socials';
import '@mod-mso/pages/organizations/organizations';
import '@mod-mso/pages/search/search';

import { WWBasicSite } from '@mod-ww/webdesigns/template/ww';

export class MSOSite extends WWBasicSite {
  constructor() {
    super();
  }
}

dompack.onDomReady(() => {
  const scrollerEl = document.querySelector('#go-up img');
  if (scrollerEl) {
    scrollerEl.addEventListener('click', () => window.scrollTo(0, 0));
  }

  document.addEventListener('scroll', onScroll);
  onScroll();

  AOS.init();

  let searchforms = dompack.qSA('.header__col--search');

  searchforms.forEach((searchform) => {
    const searchbutton = searchform.querySelector('.searchicon');

    searchbutton.addEventListener('click', (evt) => {
      if (
        dompack.qS('html.searchexpanded') == undefined &&
        searchform.closest('.mm-listview') == undefined
      ) {
        evt.preventDefault();
        dompack.qS('html').classList.toggle('searchexpanded');
      }

      // Waiting for the transition to finish
      setTimeout(function () {
        searchform.querySelector('.searchbar').focus();
      }, 500);
    });
  });

  if (searchforms.length > 0) {
    dompack.qS('#searchoverlay').addEventListener('click', (evt) => {
      dompack.qS('html').classList.toggle('searchexpanded');
    });
  }

  // Set year in copyrightfooter
  let yearfooter = dompack.qS('#year');
  if (yearfooter != undefined) {
    const currentTime = new Date();
    const year = currentTime.getFullYear();
    yearfooter.innerHTML = year;
  }
});

function getScrollY() {
  return window.scrollY || window.pageYOffset || document.body.scrollTop;
}

function onScroll() {
  var scrollPos = getScrollY();
  dompack.toggleClass(document.documentElement, 'scroll', scrollPos > 0);
}
