import './events.scss';

import { RPCFormBase } from '@mod-publisher/js/forms';
import { registerHandler } from '@mod-publisher/js/forms';
import * as whintegration from '@mod-system/js/wh/integration';
import * as dompack from 'dompack';

class EventForm extends RPCFormBase {
  constructor(node) {
    super(node);
  }

  // getFormExtraSubmitData() {
  //   return { extra: 69 };
  // }

  async onSubmitSuccess(result) {
    if (whintegration.config.obj.link) {
      window.location.replace(whintegration.config.obj.link);
    }
  }
}

class RegisterForm extends RPCFormBase {
  constructor(node) {
    super(node);
  }
}

dompack.onDomReady(() => {
  const isEventFormPage =
    document.documentElement.classList.contains('page-eventform');

  if (isEventFormPage) {
    registerHandler('mso:eventregisterhandler', (node) => new EventForm(node));
  }

  if (document.documentElement.classList.contains('page-registerform')) {
    registerHandler('mso:registration', (node) => new RegisterForm(node));
  }

  if (isEventFormPage) {
    const errorNode = dompack.qS('#errortext');
    if (!errorNode) return;

    for (const field of dompack.qSA(
      '.wh-form .wh-form__fieldgroup:not(.wh-form__fieldgroup--richtext)'
    )) {
      dompack.remove(field);
    }

    dompack.remove(dompack.qS('.wh-form__navbuttons'));

    const firstPage = dompack.qS('.wh-form__page--visible');

    firstPage.insertAdjacentHTML('beforeend', errorNode.innerHTML);
  }
});
